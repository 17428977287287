<template>
  <div>
    <div class="mb-3">
      {{ question.header }}
    </div>
    <ul>
      <li
        v-for="(option,index) in question.options"
        :key="index"
      >
        <va-checkbox
          :label="option.label"
          v-model="option.selected"
        />
        <div v-if="option.selected && option.openEnded">
          <textarea :placeholder="openEndedPlaceholder" />
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: ['data'],
  data() {
    return {
      componentKey: 0,
      question: {},
    };
  },
  created() {
    this.parseQuestion();
    // const tempQuestion =
  },
  // computed: {
  //   question() {
  //     const tempQuestion = JSON.parse(JSON.stringify(this.data.data));

  //     tempQuestion.options = tempQuestion.options.map((option)=>{
  //       console.log('it is mapping the options');
  //       return {...option, selected: false};
  //     });

  //     return tempQuestion;
  //     // const tempQuestion = this.data.map((prop)=>{
  //     //   return {...prop};
  //     // });
  //     // console.log('temp question', tempQuestion);
  //     // return tempQuestion;
  //   },
  // },
  methods: {
    parseQuestion() {
      const tempQuestion = JSON.parse(JSON.stringify(this.data.data));

      tempQuestion.options = tempQuestion.options.map((option)=>{
        return {...option, selected: false};
      });

      this.question = tempQuestion;
    },
  },
  watch: {
    data() {
      this.parseQuestion();
    },
  },
};
</script>
