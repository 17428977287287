<template>
  <div
    @mouseenter="hover(true)"
    @mouseleave="hover(false)"
    style="position: relative;"
  >
    <div
      style="position: absolute; top: 5px; right: 5px;"
      v-if="selected"
    >
      <va-button
        icon="fa fa-trash"
        color="danger"
        @click="removeQuestion"
      />
    </div>
    <div v-if="debug">
      <div>Type: {{ data }}</div>
      <div>SectionID: {{ sectionId }}</div>
      <div>questionIndex: {{ questionIndex }}</div>
    </div>
    <div class="pa-4">
      <div
        v-if="data.data.required"
        style="position: absolute; color: red; left: 15px;"
      >
        *
      </div>
      <check-list
        v-if="data.type == 'checkList'"
        :data="data"
      />
      <multiple
        v-if="data.type == 'multiple'"
        :data="data"
      />
      <open-ended
        v-if="data.type == 'openEnded'"
        :data="data"
      />
      <true-false
        v-if="data.type == 'trueFalse'"
        :data="data"
      />
    </div>
  </div>
</template>

<script>
import { reject } from 'q';
import checkList from './checkList.vue';
import multiple from './multiple.vue';
import openEnded from './openEnded.vue';
import trueFalse from './trueFalse.vue';

export default {
  components: {
    checkList,
    multiple,
    openEnded,
    trueFalse,
  },
  computed: {
    selected() {
      return this.data.id === this.$store.state.evaluation.activeEvalItem;
    },
  },
  data() {
    return {
      debug: false,
    };
  },
  props: ['data', 'editing', 'sectionId', 'questionIndex'],
  methods: {
    hover(state) {
      this.$emit('child-hover', state);
    },
    showEditPanel() {
      this.$store.dispatch('toggleEvalEditPanel', true);
    },
     async removeQuestion() {
      await new Promise(async (resolve, reject) => {
        //Iterate through the evaluation object in the store to find the question that is "hiding" the current question (if it is) 
       for await (const section of this.$store.state.evaluation.evaluation) {
        for await (const question of section.data.questions) {
          if(question.showRequirements){
            //wait 3 seconds
            /** 
             # currentID = Id of the Object that is "hidden"
             # targetID = Id of the Object that is "hiding" the currentID
            */
            let currentID = question.showRequirements.questionId;
            let targetID = this.$store.state.evaluation.activeEvalItem;
            if(currentID == targetID){
              //return a promisse with sweetalert
              await this.$swal.fire({
                title: 'Warning',
                text: "This question is required by another question. If you delete, the other question will unattach this question as a trigger. Are you sure you want to delete this question?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
              }) .then((result) => {
                if (result.value) {
                  //remove showRequirements from the question
                  question.showRequirements = null;
                  resolve();
                }
              })
            }
          }
        }}
        resolve();
      }).then((result)=>{
        //Check if the question has already been deleted from the loop above
        this.$store.dispatch('removeQuestion', {
          sectionId: this.sectionId,
          questionIndex: this.questionIndex,
        });
        this.$emit('remove-question');
      })
    },
  },
};
</script>
