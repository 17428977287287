<template>
  <div>
    <div
      class="flex text-center"
      v-if="$store.state.evaluation.length == 0"
    >
      <div
        @click="addElement('section',0)"
        class="componentSelect"
      >
        No questions or sections
      </div>
    </div>
    <div v-else>
      <div
        v-for="(section,index) in evaluation"
        :key="index"
      >
        <eval-section
          :data="section.id"
          @add-question="addQuestion(index, ...arguments)"
          @remove-section="removeSection(index)"
        />
      </div>
      <div
        @click="addElement('section',evaluation.length+1)"

        class="componentSelect text-center"
      >
        Add section
      </div>
    </div>
  </div>
</template>
<script>
import evalSection from './section.vue';
export default {
  components: {
    evalSection,
  },
  computed: {
    evaluation() {
      return this.$store.state.evaluation.evaluation;
    },
  },
  methods: {

    addQuestion(index, section) {
      this.$emit('add-question', {index, section});
    },
    addElement(type, index = 0) {
      this.$emit('add-element', {'type': type, 'index': index});
    },
    removeSection(index) {
      this.$emit('remove-section', index);
    },
  },
};
</script>

