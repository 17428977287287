<template>
  <div>
    <div class="mb-3">
      {{ question.header }}
    </div>
    <ul class="pl-2">
      <div class="row align-content--space-around">
        <div
          v-for="(option,index) in question.options"
          :key="index"
        >
          <va-radio-button
            :option="option.value"
            :label="option.label"
            v-model="selectedRadioOption"
          />
        </div>
      </div>
    </ul>
  </div>
</template>
<script>
export default {
  props: ['data'],
  data() {
    return {
      selectedRadioOption: '',
    };
  },
  computed: {
    question() {
      return {...this.data.data};
    },
  },
};
</script>
