<template>
  <div>
    <div v-if="component.type !== 'section'">
      <va-checkbox
        :label="'Required'"
        v-model="component.data.required"
      />
    </div>
    <div class="mb-4">
      <div>
        <va-input
          label="Header:"
          v-model="component.data.header"
        />
      </div>

      <div
        v-if="component.type === 'multiple'"
        class="flex xs12"
      >
        <div class="row align-content--space-around">
          <va-radio-button
            option="option1"
            label="Excellent"
          />
          <va-radio-button
            option="option1"
            label="Very Good"
          />
          <va-radio-button
            option="option1"
            label="Good"
          />
          <va-radio-button
            option="option1"
            label="Fair"
          />
          <va-radio-button
            option="option1"
            label="Poor"
          />
        </div>
      </div>
      <div
        v-if="component.type === 'trueFalse'"
        class="flex xs12"
      >
        <div class="row align-content--space-around">
          <va-radio-button
            option="yesno"
            label="Yes"
          />
          <va-radio-button
            option="yesno"
            label="No"
          />
        </div>
      </div>
      <div
        v-if="component.type === 'checkList'"
        class="flex xs12"
      >
        <ul>
          <li
            class="mb-3"
            v-for="(option,index) in component.data.options"
            :key="index"
          >
            <div class="flex row">
              <div class="flex xs1">
                <va-checkbox
                  :label="''"
                  :value="true"
                />
              </div>
              <div class="flex xs11">
                <va-input
                  v-model="option.body"
                  class="mb-auto"
                />
                <div class="flex row">
                  <div class="flex xs11">
                    <va-checkbox
                      :label="'Open Ended'"
                      v-model="option.openEnded"
                    />
                  </div>
                  <div class="flex xs1">
                    <va-button
                      icon="fa fa-minus"
                      color="danger"
                      @click="removeOption(index)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div>
          <div class="float-right">
            <va-button
              icon="fa fa-plus"
              color="success"
              @click="addOption"
            >
              Add Option
            </va-button>
          </div>
        </div>
      </div>
      <div v-if="component.type === 'openEnded'">
        <textarea style="width: 100%;" />
      </div>
      <!-- {{ component }} -->
    </div>
  </div>
</template>
<script>

export default {
  props: ['componentTemplate'],
  data() {
    return {
      empty: '',
      component: {},
    };
  },
  created() {
    const temp = JSON.stringify(this.componentTemplate);

    this.component = JSON.parse(temp);
  },
  methods: {
    editDone() {
      this.$emit('edit-done', this.component);
    },
    addOption() {
      this.component.data.options.push({'body': `option ${this.component.data.options.length+1}`, 'openEnded': false});
    },
    removeOption(index) {
      this.component.data.options.splice(index, 1);
    },
  },
};
</script>
