<template>
  <div class="flex">
    <div class="mb-3">
      {{ question.header }}
    </div>
    <textarea />
  </div>
</template>
<script>
export default {
  props: ['data'],
  computed: {
    question() {
      return {...this.data.data};
    },
  },
};
</script>
