class EvalEditorCatcher{

    /**
     * Create an EvalEditorCatcher instance.
    * @param {object} evaluation - The evaluation object to be validated.
    * @param {string} user - The user's name associated with the evaluation.
    * @param {object} testResult - The test result object to be validated.
    */
    constructor(evaluation, user, testResult){
        this.evaluation = evaluation;
    }

    /**
     * Base check function to ensure the evaluation is valid.
     * @throws {Error} - Throws an error if the evaluation is not valid.
   */
    base_check(){
        //check if evaluation is valid
        if(!this.evaluation){
            throw new Error('Evaluation is not valid');
        }
    }

    /**
     * Validate ticket fields in the evaluation.
     * Checks if questions with "Will Create Ticket?" checked have their requirements filled.
     * @throws {Error} - Throws an error if the ticket requirements are not filled.
   */
    validate_ticket_fields(){
        try {
            this.base_check();
            let errMessage = `have "Will Create Ticket?" checked but the requirements are not filled`
            // Iterating over the Sections
            for (let _eval of this.evaluation) {
              // Iterating over the questions inside the Sections
              for (let question of _eval.data.questions) {
                // If the question is hidden, check if the question that is hiding has filled the showRequirements
                if (question.data.toggleTicket) {
                  //convert question.showRequirements.answerIndex to string
                  let categoryFieldString = false;
                  try {
                     categoryFieldString = question.data.category.toString();
                  } catch (error) {
                    throw new Error(`The question "${question.data.header}" ${errMessage}`);
                  }
                  if (!categoryFieldString) {
                    throw new Error(`The question "${question.data.header}" ${errMessage}`);
                  }
                }
              }
            }
        } catch (error) {
            throw error;
        }
    }
    
    /**
     * Validate hidden fields in the evaluation.
     * Checks if questions marked as hidden have their "Hidden Until Answer" requirements filled.
     * @throws {Error} - Throws an error if the hidden requirements are not filled.
   */
    validate_hidden_fields(){
        try {
            this.base_check();
            let errMessage = `is hidden and has "Hidden Until Answer", but the requirements are not filled`
            // Iterating over the Sections
            for (let _eval of this.evaluation) {
              // Iterating over the questions inside the Sections
              for (let question of _eval.data.questions) {
                // If the question is hidden, check if the question that is hiding has filled the showRequirements
                if (question.data.hidden) {
                  //convert question.showRequirements.answerIndex to string
                  let answerIndexString = false;
                  try {
                     answerIndexString = question.showRequirements.answerIndex.toString();
                  } catch (error) {
                    throw new Error(`The question "${question.data.header}" ${errMessage}`);
                  }
                  if (!question.showRequirements?.questionId || !answerIndexString) {
                    throw new Error(`The question "${question.data.header}" ${errMessage}`);
                  }
                }
              }
            }
        } catch (error) {
            throw error;
        }
    }
}


export default EvalEditorCatcher;