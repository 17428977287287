<template>
  <div class="componentSelect pa-3">
    <va-icon
      :name="`${iconPrefix} ${icon}`"
      class="mr-2"
    />
    {{ data.displayName }}
  </div>
</template>
<script>
export default {
  props: ['data'],
  data() {
    return {
      icon: 'folder-o',
      iconPrefix: 'fa',
    };
  },

  created() {
    switch (this.data.type) {
      case 'multiple':
        this.icon = 'fa-star-half-empty';
        break;
      case 'trueFalse':
        this.icon = 'fa-exchange';
        break;
      case 'checkList':
        this.icon = 'fa-check-square';
        break;
      case 'openEnded':
        this.icon = 'fa-pencil-square-o';
        break;

      default:
        this.icon = 'fa-folder-o';
        break;
    }
  },
};
</script>

