<template>
  <div>
    <div
      v-for="(component,index) in components"
      :key="index"
    >
      <div @click="emitAdd(component)">
        <component-thumbnail
          :data="component"
        />
      </div>
    </div>
  </div>
</template>
<script>
import evalComponents from '../../data/evalComponents';
import componentThumbnail from './componentThumbnail.vue';
export default {
  components: {
    componentThumbnail,
  },

  data() {
    return {
      components: evalComponents.filter((component) => component.type !== 'section'),
    };
  },
  methods: {
    emitAdd(component) {
      this.$emit('add-item', component);
    },
  },
};
</script>
