<template>
  <div
    :style="[
      { background: 'rgb(243,243,243)' },
      {position: 'relative'}
    ]"
    class="pa-4 my-3"
    :class="[
      {
        componentSelect:!(hovering && selected) && hovering && !childHovering
      },
      {
        selectedComponent: selected
      },
      {
        hiddenElement: section.data.hidden
      }]"
    @click.self="setActiveItem(data)"
    @mouseenter.self="hovering = true"
    @mouseleave.self="hovering = false"
  >
    <div
      style="position: absolute; top: 5px; right: 5px;"
      v-if="selected"
    >
      <va-button
        icon="fa fa-trash"
        color="danger"
        @click="removeSection"
      />
    </div>
    <h4
      @click.self="setActiveItem(data)"
      style="border-bottom: 1px solid black;"
    >
      {{ section.data.header }}
    </h4>
    <div
      class="pt-2"
    >
      <div
        class="componentSelect"
        v-if="questions.length === 0"
        @click="addQuestionToSection(0)"
      >
        <span> No Questions. Click to add one</span>
      </div>
      <div
        v-for="(question,index) in questions"
        :key="index"
        class="mt-1"
        @click="setActiveItem(question.id)"
        :class="[{hiddenElement:question.data.hidden}]"
        :style="$store.state.evaluation.activeEvalItem === question.id ? { border: '2px solid blue', margin: '-2px'} : { border: 'none'}"
      >
        <question-component
          :class="[{componentSelect:true}]"
          :data="question"
          :section-id="section.id"
          :question-index="index"
          @child-hover="childHover"
          :editing="$store.state.evaluation.activeEvalItem === question"
          @remove-question="removeQuestion(index)"
        />
      </div>
      <div
        class="componentSelect text-center mt-3"
        v-if="questions.length !== 0"
        @click="addQuestionToSection(questions.length)"
      >
        <span>Add Question</span>
      </div>
    </div>
    <div v-if="debug">
      <div>Section: {{ section }}</div>
      <div>Questions: {{ questions }}</div>
      <div>ID: {{ data }}</div>
    </div>
  </div>
</template>
<script>
import questionComponent from './question.vue';
import transitionFade from './TransitionFade.vue';
import TransitionExpand from '../admin/app-sidebar/components/TransitionExpand.vue';
export default {
  components: {
    questionComponent,
    transitionFade,
    TransitionExpand,
  },
  data() {
    return {
      debug: false,
      hovering: false,
      childHovering: false,
    };
  },
  computed: {
    section() {
      return this.$store.state.evaluation.evaluation.find((section)=>{
        return section.id === this.data;
      });
    },
    selected() {
      return this.section.id === this.$store.state.evaluation.activeEvalItem;
    },
    questions() {
      return this.$store.state.evaluation.evaluation.find((section)=>{
        return section.id === this.data;
      }).data['questions'];
    },
  },
  methods: {
    childHover(state) {
      this.childHovering = state;
    },
    setActiveItem(item) {
      this.$store.dispatch('setEvalItem', item);
    },
    addQuestionToSection(index) {
      this.$emit('add-question', {'sectionId': this.data, 'index': index});
    },
    removeQuestion(questionIndex) {
      // this.data.data.questions.splice(questionIndex, 1);
      // console.log('removing question', questionIndex);
    },
    removeSection() {
      this.$emit('remove-section');
    },
    showEditPanel() {
      console.log('show edit panel');
    },
  },
  props: ['data'],
};
</script>
<style>
.hiddenElement {
  background-image: linear-gradient(45deg, #ffffff 31.82%, #ebebeb 31.82%, #ebebeb 50%, #ffffff 50%, #ffffff 81.82%, #ebebeb 81.82%, #ebebeb 100%) !important;
  background-size: 77.78px 77.78px;
  margin-left: 3em !important;
}
</style>

