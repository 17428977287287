<template>
  <div>
    <div class="mb-3">
      {{ question.header }}
    </div>
    <ul class="pl-2">
      <div class="row align-content--space-around">
        <div
          v-for="(option,index) in question.options"
          :key="index"
        >
          <va-radio-button
            :option="option.value"
            :label="option.label"
            v-model="radioSelectedOption"
          />
        </div>
      </div>
    </ul>
  </div>
</template>
<script>
export default {
  props: ['data'],
  data() {
    return {
      componentKey: 0,
      question: {},
      radioSelectedOption: '',
    };
  },
  created() {
    this.parseQuestion();
    // const tempQuestion =
  },
  methods: {
    parseQuestion() {
      const tempQuestion = JSON.parse(JSON.stringify(this.data.data));

      tempQuestion.options = tempQuestion.options.map((option)=>{
        return {...option, selected: false};
      });

      this.question = tempQuestion;
    },
  },
  watch: {
    data() {
      this.parseQuestion();
    },
  },
};
</script>
