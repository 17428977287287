export default [
  {
    displayName: 'Section',
    type: 'section',
    data: {
      header: 'Default Header',
      required: true,
      hidden: false,
      questions: [],
    },
  },
  {
    displayName: 'Multiple Choice',
    type: 'multiple',
    data: {
      header: 'My understanding of Objective:',
      required: true,
      hidden: false,
      options: [{
        label: 'Excellent',
        value: '5',
      },
      {
        label: 'Very Good',
        value: '4',
      },
      {
        label: 'Good',
        value: '3',
      },
      {
        label: 'Fair',
        value: '2',
      },
      {
        label: 'Poor',
        value: '1',
      },
      ],
    },
  },
  {
    displayName: 'True / False',
    type: 'trueFalse',
    data: {
      header: 'Do you better understand?',
      required: true,
      hidden: false,
      openEnded: false,
      options: [
        {
          label: 'Yes',
          value: 'yes',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
  },
  {
    displayName: 'Checkbox List',
    type: 'checkList',
    data: {
      header: 'What will you do differently?',
      required: true,
      hidden: false,
      options: [
        {
          label: 'option 1',
          openEnded: false,
        },
        {
          label: 'option 2',
          openEnded: false,
        },
        {
          label: 'option 3',
          openEnded: false,
        },
        {
          label: 'Other',
          openEnded: true,
        },
      ],

    },
  },
  {
    displayName: 'Open Ended',
    type: 'openEnded',
    data: {
      header: 'We welcome your comments for improvement',
      required: true,
      hidden: false,

    },
  },
];
