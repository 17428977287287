<template>
  <transition name="fade">
    <slot />
  </transition>
</template>
<script>
export default {

};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: all 10s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: scaleY(0);
}
</style>
